import * as yup from "yup";
import "yup-phone";

export const CUSTOMER_SCHEMA = yup.object().shape({
  name: yup
    .string()
    .required("Customer name is required.")
    .label("Customer Name"),
  description: yup.string().notRequired(),
  // phone: yup.string().notRequired().phone('US').label('Phone'),
  email: yup.string().required("Email is required.").email().label("Email"),
});
