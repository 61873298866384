<template>
  <table
    class="min-w-full divide-y divide-gray-200"
    data-testid="customers-table"
  >
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Customer
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Email/Phone
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Prefix
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          # of Invoices
        </th>
        <th scope="col" class="relative px-6 py-3">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-for="customer in customers" :key="customer.Id">
        <td class="whitespace-nowrap px-6 py-4 text-left">
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {{ customer.Name }}
            </div>
            <div class="max-w-xs truncate text-sm text-gray-500">
              {{ customer.Description }}
            </div>
          </div>
        </td>

        <td class="whitespace-nowrap px-6 py-4 text-left">
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {{ customer.Email }}
            </div>
            <div class="max-w-sm truncate text-sm text-gray-500">
              {{ customer.Phone }}
            </div>
          </div>
        </td>
        <td class="whitespace-nowrap px-6 py-4 text-left">
          <div class="ml-4">
            <span
              v-if="customer.InvoicePrefix !== null"
              class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800"
            >
              {{ customer.InvoicePrefix }}
            </span>
          </div>
        </td>
        <td class="whitespace-nowrap px-6 py-4 text-left">
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-700">
              {{ customer.InvoicesCount }}
            </div>
          </div>
        </td>
        <td
          class="flex justify-end space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
        >
          <button
            type="button"
            @click="toClipboard(customer.Phone)"
            v-tippy="{
              content: 'Copy Phone',
              animation: 'scale',
              animateFill: false,
              theme: 'tomato',
            }"
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800"
          >
            <PhoneIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
          </button>
          <button
            type="button"
            @click="toClipboard(customer.Email)"
            v-tippy="{
              content: 'Copy Email',
              animation: 'scale',
              animateFill: false,
              theme: 'tomato',
            }"
            class="focus:outline-none inline-flex items-center rounded-md border border-2 border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800"
          >
            <MailIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
          </button>
          <button
            type="button"
            v-tippy="{
              content: 'Edit',
              animation: 'scale',
              animateFill: false,
              theme: 'tomato',
            }"
            data-testid="edit-customer"
            @click="handleEdit(customer)"
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800"
          >
            <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
          </button>
          <button
            type="button"
            v-if="customer.InvoicesCount === 0"
            v-tippy="{
              content: 'Archive',
              animation: 'scale',
              animateFill: false,
              theme: 'tomato',
            }"
            @click="handleDelete(customer.Id)"
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-red-300 px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <ArchiveIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// import { reactive } from "vue";
import {
  MailIcon,
  PhoneIcon,
  PencilIcon,
  ArchiveIcon,
} from "@heroicons/vue/outline";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";
import { toClipboard } from "@soerenmartius/vue3-clipboard";

export default {
  directives: {
    tippy: directive,
  },
  components: {
    MailIcon,
    PhoneIcon,
    PencilIcon,
    ArchiveIcon,
  },
  props: {
    customers: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    // const state = reactive({
    //   customers: props.customers,
    // });

    const handleEdit = (customer) => {
      emit("selectCustomer", customer);
    };

    const handleDelete = (id) => {
      emit("handleDelete", id);
    };

    return {
      // state,
      handleEdit,
      handleDelete,
      toClipboard,
    };
  },
};
</script>

<style lang="scss" scoped></style>
