<template>
  <slideover title="Create Customer">
    <!-- Replace with your content -->
    <form @submit="onSubmit" class="flex h-full flex-col bg-white">
      <div class="flex flex-1 flex-col justify-between">
        <div class="divide-y divide-gray-200 px-4 sm:px-6">
          <div class="space-y-6 pt-6 pb-5">
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="name"
                  v-model="name"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <p class="mt-2 flex text-sm text-red-600">
                  <ExclamationCircleIcon
                    v-if="errors.name && meta.dirty"
                    class="mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.name }}
                </p>
              </div>
            </div>

            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Description
              </label>
              <div class="mt-1">
                <textarea
                  name="description"
                  v-model="description"
                  rows="4"
                  class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Phone
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="phone"
                  v-model="phone"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <p class="mt-2 flex text-sm text-red-600">
                  <ExclamationCircleIcon
                    v-if="errors.phone && meta.dirty"
                    class="mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.phone }}
                </p>
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="email"
                  v-model="email"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <p
                  v-if="errors.email && meta.dirty"
                  class="mt-2 flex text-sm text-red-600"
                  id="email-error"
                >
                  <ExclamationCircleIcon
                    v-if="errors.email && meta.dirty"
                    class="mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.email }}
                </p>
              </div>
            </div>
            <div>
              <label
                for="Invoice-Prefix"
                class="flex justify-between text-sm font-medium text-gray-900"
              >
                <div>
                  Invoice Prefix
                  <span class="text-gray-500"> (optional) </span>
                </div>
                <ShowInfo
                  text="Use a unique prefix for each customer and assigns invoice numbers sequentially for a given customer"
                />
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="prefix"
                  maxlength="4"
                  v-model="invoicePrefix"
                  class="block w-full rounded-md border-gray-300 uppercase shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click="onClose"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm"
        >
          Save
        </button>
      </div>
    </form>
    <!-- /End replace -->
  </slideover>
</template>

<script>
import { ref, computed } from "vue";
import { CUSTOMER_SCHEMA } from "@/common/schemas/invoices/customer";
import { useForm, useField } from "vee-validate";
import Slideover from "@/common/components/slideover";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import ShowInfo from "@/common/components/showInfo";
export default {
  components: {
    ShowInfo,
    Slideover,
    ExclamationCircleIcon,
  },
  setup(_, { emit }) {
    const open = ref(true);
    const schema = computed(() => {
      return CUSTOMER_SCHEMA;
    });
    const initialValues = {
      name: "",
      description: "",
      phone: "",
      email: "",
      invoicePrefix: "",
    };

    const { meta, errors, handleSubmit } = useForm({
      validationSchema: schema.value,
      initialValues: initialValues,
      validateOnValueUpdate: false,
    });

    const { value: name } = useField("name");
    const { value: description } = useField("description");
    const { value: phone } = useField("phone");
    const { value: email } = useField("email");
    const { value: invoicePrefix } = useField("invoicePrefix"); // min 3 char

    const onSubmit = handleSubmit((values) => {
      const customer = {
        name: values.name,
        description: values.description,
        phone: values.phone,
        email: values.email,
        invoicePrefix: values.invoicePrefix.toUpperCase(),
      };
      emit("onCreateCustomer", customer);

      // reset form
      name.value = "";
      description.value = "";
      phone.value = "";
      email.value = "";
      invoicePrefix.value = "";
      // meta.reset();
      // errors.reset();
    });

    const onClose = () => {
      emit("onClose", true);
    };

    return {
      open,
      meta,
      errors,
      name,
      description,
      phone,
      email,
      invoicePrefix,
      onClose,
      onSubmit,
    };
  },
};
</script>
