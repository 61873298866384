<template>
  <ul
    role="list"
    class="mt-8 mb-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
  >
    <li
      v-for="customer in customers"
      :key="customer.Id"
      class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
    >
      <div class="flex w-full items-center justify-between space-x-6 p-6">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3 class="truncate text-sm font-medium text-gray-900">
              {{ customer.Name }}
            </h3>
          </div>
          <p class="mt-1 truncate text-left text-sm text-gray-500">
            {{ customer.Description }}&nbsp;
          </p>
        </div>
        <div class="">
          <button
            type="button"
            v-tippy="{
              content: 'Edit',
              animation: 'scale',
              animateFill: false,
              theme: 'tomato',
            }"
            @click="handleEdit(customer)"
            class="focus:outline-none mr-2 inline-flex items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800"
          >
            <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
          </button>
          <button
            type="button"
            v-if="customer.InvoicesCount === 0"
            v-tippy="{
              content: 'Archive',
              animation: 'scale',
              animateFill: false,
              theme: 'tomato',
            }"
            @click="handleDelete(customer.Id)"
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-red-300 px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <ArchiveIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
          </button>
        </div>
      </div>
      <div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <div class="flex w-0 flex-1">
            <a
              @click="toClipboard(customer.Email)"
              href="#"
              class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              <MailIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3">Copy Email</span>
            </a>
          </div>
          <div class="-ml-px flex w-0 flex-1">
            <a
              @click="toClipboard(customer.Phone)"
              href="#"
              class="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border-transparent border-gray-300 py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              <PhoneIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3">Copy Phone</span>
            </a>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
// import { reactive } from "vue";

import {
  MailIcon,
  PhoneIcon,
  PencilIcon,
  ArchiveIcon,
} from "@heroicons/vue/solid";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

import { toClipboard } from "@soerenmartius/vue3-clipboard";

export default {
  components: {
    MailIcon,
    PhoneIcon,
    PencilIcon,
    ArchiveIcon,
  },
  directives: {
    tippy: directive,
  },
  props: {
    customers: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    // const state = reactive({
    //   customers: props.customers,
    // });

    const handleEdit = (customer) => {
      emit("selectCustomer", customer);
    };

    const handleDelete = (id) => {
      emit("handleDelete", id);
    };

    return {
      // state,
      handleEdit,
      handleDelete,
      toClipboard,
    };
  },
};
</script>

<style lang="scss" scoped></style>
