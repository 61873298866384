<template>
  <slideover title="Edit Customer" data-testid="Edit Customer">
    <!-- Replace with your content -->
    <form class="flex h-full flex-col bg-white">
      <div class="flex flex-1 flex-col justify-between">
        <div class="divide-y divide-gray-200 px-4 sm:px-6">
          <div class="space-y-6 pt-6 pb-5">
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="name"
                  v-model="name"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <p
                  v-if="errors.name && meta.dirty"
                  class="mt-2 flex text-sm text-red-600"
                  id="name-error"
                >
                  <ExclamationCircleIcon
                    class="mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.name }}
                </p>
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Description
              </label>
              <div class="mt-1">
                <textarea
                  name="description"
                  v-model="description"
                  rows="4"
                  class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Phone
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="customer-phone"
                  v-model="phone"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <p
                  v-if="errors.phone && meta.dirty"
                  class="mt-2 flex text-sm text-red-600"
                  id="phone-error"
                >
                  <ExclamationCircleIcon
                    class="mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.phone }}
                </p>
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="customer-email"
                  id="customer-email"
                  v-model="email"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <p
                  v-if="errors.email && meta.dirty"
                  class="mt-2 flex text-sm text-red-600"
                  id="email-error"
                >
                  <ExclamationCircleIcon
                    class="mr-1 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  {{ errors.email }}
                </p>
              </div>
            </div>
            <div>
              <label
                for="project-name"
                class="block text-sm font-medium text-gray-900"
              >
                Invoice Prefix
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="prefix"
                  maxlength="4"
                  v-model="invoicePrefix"
                  class="block w-full rounded-md border-gray-300 uppercase shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
          @click="onClose"
        >
          Cancel
        </button>
        <button
          type="button"
          @click="onSubmit"
          class="focus:outline-none ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
        >
          Update
        </button>
      </div>
    </form>
    <!-- /End replace -->
  </slideover>
</template>

<script>
import { ref, computed } from "vue";
import { CUSTOMER_SCHEMA } from "@/common/schemas/invoices/customer";
import { useField, useForm } from "vee-validate";
import Slideover from "@/common/components/slideover";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Slideover,
    ExclamationCircleIcon,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const open = ref(true);
    const schema = computed(() => {
      return CUSTOMER_SCHEMA;
    });

    const initialValues = {
      name: props.customer.Name,
      description: props.customer.Description,
      phone: props.customer.Phone,
      email: props.customer.Email,
      invoicePrefix: props.customer.invoicePrefix,
    };

    // const state = reactive({
    //   name: props.customer.Name,
    //   description: props.customer.Description,
    //   phone: props.customer.Phone,
    //   email: props.customer.Email,
    // });

    const { meta, errors, handleSubmit } = useForm({
      validationSchema: schema.value,
      validateOnMount: false,
      initialValues: initialValues,
    });

    const { value: name } = useField("name");
    const { value: description } = useField("description");
    const { value: phone } = useField("phone");
    const { value: email } = useField("email");
    const { value: invoicePrefix } = useField("invoicePrefix");

    const onSubmit = handleSubmit((values) => {
      const customer = {
        id: props.customer.Id,
        name: values.name,
        description: values.description,
        phone: values.phone,
        email: values.email,
        invoicePrefix: values.invoicePrefix?.toUpperCase(),
      };
      emit("onUpdateCustomer", customer);
    });

    const onClose = () => {
      emit("onEditClose", true);
    };

    return {
      open,
      errors,
      onClose,
      onSubmit,
      name,
      description,
      phone,
      email,
      invoicePrefix,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped></style>
