import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function useCustomersRepository() {
  const customers = ref([]);
  const allCustomers = ref([]);
  const totalResults = ref(0);
  const loading = ref(false);

  const getCustomers = async (page, limit, search) => {
    loading.value = true;

    let response = await API.Organization.Customers.get(page, limit, search);

    if (response.data.Success) {
      customers.value = response.data.List;
      totalResults.value = response.data.TotalResults;
      loading.value = false;
    } else {
      loading.value = false;
      ElNotification.error({
        title: "Error",
        message: "Problem while fetching Customers",
      });
    }
  };

  const getAllCustomers = async () => {
    loading.value = true;
    let response = await API.Organization.Customers.getAll();
    if (response.data.Success) {
      allCustomers.value = response.data.List;
      loading.value = false;
    } else {
      ElNotification.error({
        title: "Error",
        message: "Problem while fetching Customers",
      });
      loading.value = false;
    }
  };

  const saveCustomer = async (data) => {
    loading.value = true;
    try {
      await API.Organization.Customers.save(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "New customer created",
        type: "success",
      });
      getCustomers(1, 10, "");
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Customers",
        type: "warning",
      });
    }
  };

  const saveCustomerAndReturnValue = async (data) => {
    loading.value = true;
    try {
      const response = await API.Organization.Customers.save(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Customer created",
        type: "success",
      });

      return response;
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while creating Customer",
        type: "warning",
      });
    }
  };

  const updateCustomer = async (data) => {
    loading.value = true;
    try {
      await API.Organization.Customers.update(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Customer updated",
        type: "success",
      });
      getCustomers(1, 10, "");
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while updating Customer " + error.Message,
        type: "warning",
      });
    }
  };

  const deleteCustomer = async (id) => {
    loading.value = true;
    try {
      await API.Organization.Customers.delete(id);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Customer archived",
        type: "success",
      });
      getCustomers(1, 10, "");
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while archived Customer",
        type: "warning",
      });
    }
  };

  return {
    loading: readonly(loading),
    customers: readonly(customers),
    totalResults: readonly(totalResults),
    getCustomers,
    saveCustomer,
    updateCustomer,
    deleteCustomer,
    saveCustomerAndReturnValue,
    getAllCustomers,
    allCustomers: readonly(allCustomers),
  };
}
