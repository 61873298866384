<template>
  <div class="layout" data-testid="Customers">
    <Title name="Customers" />

    <div class="flex justify-between">
      <Search v-model:search="pagination.search" placeholder="Find customer" />
      <div class="flex justify-end">
        <button
          type="button"
          @click="state.view = 'table'"
          v-tippy="{
            content: 'Table Layout',
            animation: 'scale',
            animateFill: false,
            theme: 'tomato',
          }"
          :class="
            state.view === 'table'
              ? 'bg-gray-200 text-gray-800'
              : 'bg-white text-gray-800'
          "
          :disabled="state.view === 'table'"
          class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10"
        >
          <TableIcon class="h-5 w-5 text-gray-400" />
        </button>
        <button
          type="button"
          @click="state.view = 'list'"
          :class="
            state.view === 'list'
              ? 'bg-gray-200 text-gray-800'
              : 'bg-white text-gray-800'
          "
          :disabled="state.view === 'list'"
          v-tippy="{
            content: 'View Layout',
            animation: 'scale',
            animateFill: false,
            theme: 'tomato',
          }"
          class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10"
        >
          <ViewListIcon class="h-5 w-5 text-gray-400" />
        </button>
        <button
          type="button"
          class="order-0 f inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-gray-100 sm:order-1 sm:ml-3"
          v-tippy="{
            content: 'Download Customers as .csv file',
            animation: 'scale',
            animateFill: false,
            theme: 'tomato',
          }"
          @click="downloadCSV"
        >
          <DocumentDownloadIcon class="h-5 w-5 text-gray-400" />
        </button>
        <button
          type="button"
          @click="openSlide"
          class="order-0 f inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 sm:order-1 sm:ml-3"
        >
          Add Customer
        </button>
      </div>
    </div>
    <div class="my-4 rounded-md bg-blue-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <InformationCircleIcon
            class="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">
            Customers can be archived if the number of sent invoices is 0.
          </p>
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      class="relative mt-4 flex flex-col"
      v-if="customers.length > 0 && state.view === 'table'"
    >
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full py-4 px-8 align-middle">
            <div
              class="overflow-hidden border border-b border-gray-200 sm:rounded-lg"
            >
              <CustomersTable
                :customers="customers"
                @selectCustomer="selectCustomer"
                @handleDelete="handleDelete"
              />
              <pagination :pagination="pagination" @changePage="onChangePage" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="customers.length > 0 && state.view === 'list'">
      <CustomersList
        :customers="customers"
        @selectCustomer="selectCustomer"
        @handleDelete="handleDelete"
      />
      <pagination :pagination="pagination" @changePage="onChangePage" />
    </div>

    <CreateCustomer
      :show="state.toggleCreate"
      @onClose="closeSlide"
      @onCreateCustomer="onCreateCustomer"
    />
    <EditCustomer
      v-if="Object.entries(state.customer).length > 0"
      :show="state.toggleEdit"
      :customer="state.customer"
      @onEditClose="onEditClose"
      @onUpdateCustomer="onUpdateCustomer"
    />
    <el-empty
      v-if="customers.length === 0"
      description="Looks like you don't have any Customers."
    >
      <button
        type="button"
        @click="openSlide"
        class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
      >
        Create
      </button>
    </el-empty>
  </div>
  <DeleteCustomerModal v-if="state.showModal" @confirmDelete="confirmDelete" />
</template>

<script>
import { onMounted, reactive, watch } from "vue";

import {
  TableIcon,
  ViewListIcon,
  DocumentDownloadIcon,
  InformationCircleIcon,
} from "@heroicons/vue/outline";
import useCustomersRepository from "@/composable/useCustomersRepository";

import Title from "@/common/components/title";
import Search from "@/common/components/search";
import CreateCustomer from "./components/_new.vue";
import EditCustomer from "./components/_edit.vue";
import CustomersList from "./components/_list.vue";
import CustomersTable from "./components/_table.vue";

import Pagination from "@/common/components/pagination";
import DeleteCustomerModal from "@/common/components/modals/DeleteCustomerModal";

import { directive } from "vue-tippy";

export default {
  components: {
    Title,
    Search,
    CreateCustomer,
    EditCustomer,
    CustomersList,
    CustomersTable,
    TableIcon,
    ViewListIcon,
    DocumentDownloadIcon,
    InformationCircleIcon,
    Pagination,
    DeleteCustomerModal,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const {
      loading,
      customers,
      allCustomers,
      totalResults,
      getCustomers,
      saveCustomer,
      updateCustomer,
      deleteCustomer,
      getAllCustomers,
    } = useCustomersRepository();

    const state = reactive({
      toggleCreate: false,
      toggleEdit: false,
      view: "table",
      customer: {},
      selectCustomerId: null,
    });

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      search: "",
      totalResults: totalResults,
    });

    const openSlide = () => {
      state.customer = {};
      state.toggleCreate = true;
    };

    const closeSlide = () => {
      state.customer = {};
      state.toggleCreate = false;
    };

    const onEditClose = () => {
      state.customer = {};
      state.toggleEdit = false;
    };

    const selectCustomer = (customer) => {
      state.customer = {};
      state.customer = customer;
      state.toggleEdit = true;
    };

    const onCreateCustomer = (customer) => {
      saveCustomer(customer);
      closeSlide();
    };

    const onUpdateCustomer = (customer) => {
      updateCustomer(customer);
      state.toggleEdit = false;
      customer = {};
    };

    const onChangePage = (page) => {
      pagination.page = page;
      getCustomers(pagination.page, pagination.pageSize, pagination.search);
    };

    const handleDelete = (id) => {
      state.selectCustomerId = id;
      state.showModal = true;
      // deleteCustomer(id);
    };

    const confirmDelete = () => {
      deleteCustomer(state.selectCustomerId);
      state.showModal = false;
    };

    onMounted(() => {
      getCustomers(pagination.page, pagination.pageSize, pagination.search);
      getAllCustomers();
    });

    watch(
      () => pagination.search,
      (search) => {
        getCustomers(1, 10, search);
      },
      { deep: true }
    );

    const downloadCSV = () => {
      // Example data
      const csvData = allCustomers.value.map((customer) => [
        customer.Name,
        customer.Description,
        customer.Email,
        customer.Phone,
        customer.InvoicesCount,
        customer.InvoicePrefix,
      ]);

      // add headers
      csvData.unshift([
        "Name",
        "Description",
        "Email",
        "Phone",
        "Invoices Count",
        "Invoice Prefix",
      ]);

      // Convert data to CSV format
      const csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((row) => row.join(",")).join("\n");

      // Create a link element
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `customers-on-${new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })}.csv`
      );

      // Append the link to the body
      document.body.appendChild(link);

      // Click the link to start download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    };

    return {
      state,
      loading,
      customers,
      pagination,
      openSlide,
      closeSlide,
      onEditClose,
      onUpdateCustomer,
      onChangePage,
      onCreateCustomer,
      selectCustomer,
      deleteCustomer,
      confirmDelete,
      handleDelete,
      downloadCSV,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
</style>
